import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import CustomButton from '../../RadioUI/CustomButton'
import Icon from '../../RadioUI/Icon'
import FavouriteButton from '../Favourite/FavouriteButton'
import { useFavourite } from '../hooks/useFavourite'
import { useLoginNotification } from '../Auth/LoginNotificationContext'
import { useAuthContext } from '../Auth/AuthContext'

const noop = () => {}

const EpisodeMenuFavourite = ({
  isHiddenState,
  setIsHiddenState,
  isAuthenticated,
  isPodCast,
  loginUrl,
  seriesId,
  isEpisode,
  onUnFavourite = noop,
  onFavourite = noop
}) => {
  const { isFavourite, toggleFavourite, refreshFavourite } = useFavourite({
    contentId: seriesId,
    favouriteContentType: isPodCast ? 'podcast' : 'series',
    autoUpdate: false,
    onFavourite,
    onUnFavourite
  })
  const { hideNotification } = useLoginNotification()
  const { login } = useAuthContext()

  useEffect(() => {
    if (!isHiddenState) {
      refreshFavourite()
    }
  }, [isHiddenState, refreshFavourite])

  if (!isAuthenticated) {
    return (
      <CustomButton
        w="100%"
        mt={'4px'}
        mb="4px"
        d="flex"
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        as="a"
        href={loginUrl}
        type={null}
        onClick={() => {
          hideNotification()
          login()
        }}
      >
        Logg på for å følge
        <Icon iconId="nrk-star" />
      </CustomButton>
    )
  }

  return (
    <FavouriteButton
      onClick={() => {
        toggleFavourite()
        setIsHiddenState(true)
      }}
      isFavourite={isFavourite}
      flexDir="row-reverse"
      w="100%"
      mt={isEpisode ? '20px' : '4px'}
      mb="4px"
      px="4"
    />
  )
}

EpisodeMenuFavourite.propTypes = {
  isHiddenState: PropTypes.bool.isRequired,
  setIsHiddenState: PropTypes.func.isRequired,
  seriesId: PropTypes.string,
  onUnFavourite: PropTypes.func,
  onFavourite: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  isPodCast: PropTypes.bool,
  isEpisode: PropTypes.bool,
  loginUrl: PropTypes.string
}
export default EpisodeMenuFavourite
