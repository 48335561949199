import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Toast from '../Toast'
import { TogglePlaylistContext } from '../StickyPlayer/context/TooglePlaylistContext'

const QueueToast = ({ isHidden, setIsHidden }) => {
  const { closeDialog: closePlaylistDialog, setShowQueue } = useContext(TogglePlaylistContext)
  return (
    <Toast
      isHidden={isHidden}
      setIsHidden={setIsHidden}
      onClick={() => {
        closePlaylistDialog()
        setShowQueue(true)
      }}
    >
      Lagt til i køen
    </Toast>
  )
}

QueueToast.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  setIsHidden: PropTypes.func.isRequired
}

export default QueueToast
