import PropTypes from 'prop-types'
import React, { useState, useContext, useEffect } from 'react'
import { addToQueue, removeFromQueue } from '../../apiClients/personalisation'
import CustomButton from '../../RadioUI/CustomButton'
import { QueueContext } from '../Queues/QueueContext'
import { OpenQueueSvg, PlayLaterIconSvg, PlayNextIconSvg, RemoveFromQueueIconSvg } from '../Queues/QueueIcons'
import { TogglePlaylistContext } from '../StickyPlayer/context/TooglePlaylistContext'
import PseudoBox from '../../RadioUI/PseudoBox'
import { useColors } from '../../RadioUI/theme/Color'
import { useLoginNotification } from '../Auth/LoginNotificationContext'
import { useAuthContext } from '../Auth/AuthContext'

const noop = () => {}

const EpisodeMenuLoginSvg = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path d="M2.5 11H22.5V13H2.5V11Z" fill="currentColor" />
      <path d="M22.5 17H2.5V19H22.5V17Z" fill="currentColor" />
      <path d="M12.5 5H22.5V7H12.5V5Z" fill="currentColor" />
    </g>
    <path d="M4.62964 3H7L8.62964 6L7 9H4.62964L6.25928 6L4.62964 3Z" fill="currentColor" />
  </svg>
)

const EpisodeMenuQueue = ({
  isHiddenState,
  setIsHiddenState,
  episodeId,
  episodeType,
  isAuthenticated,
  isEpisode,
  episodeIsAvailable = true,
  onViewQueue = noop,
  onRemoveFromQueue = noop,
  onClickDuplicate = noop,
  onChangeQueue = noop,
  setIsMessageHiddenState = noop
}) => {
  const [isInQueue, setIsInQueue] = useState(false)
  const { queue, setQueue } = useContext(QueueContext)
  const { isDialogHidden: isPlaylistDialogHidden, openDialog: openPlaylistDialog, setShowQueue } = useContext(
    TogglePlaylistContext
  )
  const colors = useColors()
  const { hideNotification } = useLoginNotification()
  const { login } = useAuthContext()

  useEffect(() => {
    setIsInQueue(queue && queue.length > 0 && queue.find(qu => qu.id === episodeId))
  }, [queue, episodeId])

  useEffect(() => {
    if (!isHiddenState && isInQueue) {
      onClickDuplicate()
    }
  }, [isHiddenState, isInQueue, onClickDuplicate])

  const handleChangeQueue = (type, action) => {
    addToQueue(episodeId, episodeType, type).then(e => {
      setQueue(e.queue)
      setIsMessageHiddenState(false)
      setIsHiddenState(true)
    })
    onChangeQueue(action, type)
  }

  if (!isAuthenticated) {
    return (
      <CustomButton
        w="100%"
        mt={'4px'}
        mb="4px"
        d="flex"
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => {
          hideNotification()
          login()
        }}
      >
        Logg på for å legge i kø
        <EpisodeMenuLoginSvg />
      </CustomButton>
    )
  }

  return (
    <PseudoBox mt={isEpisode ? '20px' : '0'} d="flex" flexDir="column">
      {isEpisode && episodeIsAvailable && (!isInQueue || queue.findIndex(qu => qu.id === episodeId) !== 0) && (
        <CustomButton
          w="100%"
          mt="4px"
          mb="4px"
          d="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={event => {
            event.preventDefault()
            handleChangeQueue('first', isInQueue ? 'move-first' : 'play-next')
          }}
        >
          {isInQueue ? 'Flytt fremst i køen' : 'Spill som neste'}
          <PlayNextIconSvg />
        </CustomButton>
      )}
      {isEpisode &&
        episodeIsAvailable &&
        (!isInQueue || queue.findIndex(qu => qu.id === episodeId) !== queue.length - 1) && (
          <CustomButton
            w="100%"
            mt="4px"
            mb="4px"
            d="flex"
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            onClick={event => {
              event.preventDefault()
              handleChangeQueue('last', isInQueue ? 'move-last' : 'play-later')
            }}
          >
            {isInQueue ? ' Flytt sist i køen' : 'Spill senere'}
            <PlayLaterIconSvg />
          </CustomButton>
        )}
      {isPlaylistDialogHidden && (
        <CustomButton
          w="100%"
          mt="4px"
          mb="4px"
          d="flex"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            onViewQueue()
            openPlaylistDialog()
            setShowQueue(true)
            setIsHiddenState(true)
          }}
        >
          Se min kø {queue && queue.length > 0 ? `(${queue.length})` : null}
          <OpenQueueSvg />
        </CustomButton>
      )}
      {isInQueue && episodeIsAvailable && (
        <CustomButton
          color={colors.warning.light}
          w="100%"
          d="flex"
          mt="4px"
          mb="4px"
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={e => {
            e.preventDefault()
            removeFromQueue(episodeId, episodeType).then(e => setQueue(e.queue))
            onRemoveFromQueue()
            setIsHiddenState(true)
          }}
        >
          Fjern fra min kø
          <RemoveFromQueueIconSvg />
        </CustomButton>
      )}
    </PseudoBox>
  )
}

EpisodeMenuQueue.propTypes = {
  isHiddenState: PropTypes.bool.isRequired,
  setIsHiddenState: PropTypes.func.isRequired,
  episodeId: PropTypes.string,
  episodeType: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isEpisode: PropTypes.bool,
  episodeIsAvailable: PropTypes.bool,
  onClickDuplicate: PropTypes.func,
  onViewQueue: PropTypes.func,
  onRemoveFromQueue: PropTypes.func,
  onChangeQueue: PropTypes.func,
  setIsMessageHiddenState: PropTypes.func
}
export default EpisodeMenuQueue
