import React from 'react'

export const UnMarkCompleted = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.1" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5435 2.49689C15.9157 1.54532 14.0216 1 12 1V3C13.654 3 15.2038 3.44617 16.5356 4.22473L17.5435 2.49689Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.2929L14.6465 8.64645L15.3536 9.35356L12.7071 12L15.3536 14.6465L14.6465 15.3536L12 12.7071L9.35356 15.3536L8.64645 14.6465L11.2929 12L8.64645 9.35356L9.35356 8.64645L12 11.2929Z"
      fill="currentColor"
    />
    <path
      d="M14.6465 8.64645L15 8.2929L14.6465 7.93935L14.2929 8.2929L14.6465 8.64645ZM12 11.2929L11.6465 11.6465L12 12L12.3536 11.6465L12 11.2929ZM15.3536 9.35356L15.7071 9.70711L16.0607 9.35356L15.7071 9.00001L15.3536 9.35356ZM12.7071 12L12.3536 11.6465L12 12L12.3536 12.3536L12.7071 12ZM15.3536 14.6465L15.7071 15L16.0607 14.6465L15.7071 14.2929L15.3536 14.6465ZM14.6465 15.3536L14.2929 15.7071L14.6465 16.0607L15 15.7071L14.6465 15.3536ZM12 12.7071L12.3536 12.3536L12 12L11.6465 12.3536L12 12.7071ZM9.35356 15.3536L9.00001 15.7071L9.35356 16.0607L9.70711 15.7071L9.35356 15.3536ZM8.64645 14.6465L8.2929 14.2929L7.93935 14.6465L8.2929 15L8.64645 14.6465ZM11.2929 12L11.6465 12.3536L12 12L11.6465 11.6465L11.2929 12ZM8.64645 9.35356L8.2929 9.00001L7.93935 9.35356L8.2929 9.70711L8.64645 9.35356ZM9.35356 8.64645L9.70711 8.2929L9.35356 7.93935L9.00001 8.2929L9.35356 8.64645ZM14.2929 8.2929L11.6465 10.9393L12.3536 11.6465L15 9.00001L14.2929 8.2929ZM15.7071 9.00001L15 8.2929L14.2929 9.00001L15 9.70711L15.7071 9.00001ZM13.0607 12.3536L15.7071 9.70711L15 9.00001L12.3536 11.6465L13.0607 12.3536ZM12.3536 12.3536L15 15L15.7071 14.2929L13.0607 11.6465L12.3536 12.3536ZM15 14.2929L14.2929 15L15 15.7071L15.7071 15L15 14.2929ZM15 15L12.3536 12.3536L11.6465 13.0607L14.2929 15.7071L15 15ZM9.70711 15.7071L12.3536 13.0607L11.6465 12.3536L9.00001 15L9.70711 15.7071ZM8.2929 15L9.00001 15.7071L9.70711 15L9.00001 14.2929L8.2929 15ZM10.9393 11.6465L8.2929 14.2929L9.00001 15L11.6465 12.3536L10.9393 11.6465ZM11.6465 11.6465L9.00001 9.00001L8.2929 9.70711L10.9393 12.3536L11.6465 11.6465ZM9.00001 9.70711L9.70711 9.00001L9.00001 8.2929L8.2929 9.00001L9.00001 9.70711ZM9.00001 9.00001L11.6465 11.6465L12.3536 10.9393L9.70711 8.2929L9.00001 9.00001Z"
      fill="currentColor"
    />
  </svg>
)

export const MarkCompleted = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.1" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5202 3.12108C6.25369 3.82686 3 7.53346 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3V1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 6.5409 4.97673 2.01061 10.1913 1.14798L10.5202 3.12108Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8536 9.85364L10.6525 15.0547L8.21148 12.2382L8.96717 11.5833L10.7048 13.5882L15.1465 9.14653L15.8536 9.85364Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
)
