import React from 'react'

export const MoreIconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path d="M2 8H22V10H2V8Z" fill="currentColor" />
      <path d="M22 14H2V16H22V14Z" fill="currentColor" />
    </g>
  </svg>
)

export const OpenQueueSvg = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path d="M2.5 11H22.5V13H2.5V11Z" fill="currentColor" />
      <path d="M22.5 17H2.5V19H22.5V17Z" fill="currentColor" />
      <path d="M12.5 5H22.5V7H12.5V5Z" fill="currentColor" />
    </g>
    <path d="M4.62964 3H7L8.62964 6L7 9H4.62964L6.25928 6L4.62964 3Z" fill="currentColor" />
  </svg>
)

export const PlayLaterIconSvg = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path d="M12.5 19L22.5 19V17L12.5 17V19Z" fill="currentColor" />
      <path d="M8.5 13L22.5 13V11L8.5 11V13Z" fill="currentColor" />
      <path d="M22.5 7L2.5 7L2.5 5L22.5 5V7Z" fill="currentColor" />
    </g>
    <path d="M6.62964 21H9L10.6296 18L9 15H6.62964L8.25928 18L6.62964 21Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 19H9V17H4.5L4.5 11H2.5L2.5 19Z" fill="currentColor" />
  </svg>
)
export const PlayNextIconSvg = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path d="M12.5 5H22.5V7H12.5V5Z" fill="currentColor" />
      <path d="M8.5 11H22.5V13H8.5V11Z" fill="currentColor" />
      <path d="M22.5 17H2.5V19H22.5V17Z" fill="currentColor" />
    </g>
    <path d="M6.62964 3H9L10.6296 6L9 9H6.62964L8.25928 6L6.62964 3Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 5H9V7H4.5V13H2.5V5Z" fill="currentColor" />
  </svg>
)
export const RemoveFromQueueIconSvg = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g opacity="0.3">
        <path d="M2.5 5H14.5V7H2.5V5Z" fill="currentColor" />
        <path d="M2.5 11H22.5V13H2.5V11Z" fill="currentColor" />
        <path d="M22.5 17H2.5V19H22.5V17Z" fill="currentColor" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 4C24.5 6.20914 22.7091 8 20.5 8C18.2909 8 16.5 6.20914 16.5 4C16.5 1.79086 18.2909 0 20.5 0C22.7091 0 24.5 1.79086 24.5 4ZM22.5 3.25H18.5V4.75H22.5V3.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="currentcolor" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
export const AddedToQueueIconSvg = () => (
  <svg width="25" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.3">
      <path d="M1.66666 4.16667H8.33333V5.83334H1.66666V4.16667Z" fill="currentColor" />
      <path d="M1.66666 9.16667H8.33333V10.8333H1.66666V9.16667Z" fill="currentColor" />
      <path d="M18.3333 14.1667H1.66666V15.8333H18.3333V14.1667Z" fill="currentColor" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8967 4.97394L12.9364 10.7683L10 7.53905L11.2595 6.4475L13.0236 8.32407L17.7182 3.79543L18.8967 4.97394Z"
      fill="currentColor"
    />
  </svg>
)
